import { onUnmounted as Ct, getCurrentScope as Nt, onScopeDispose as kt, unref as E, getCurrentInstance as $e, watch as H, ref as R, defineComponent as Ue, useAttrs as Ot, computed as U, onMounted as it, onBeforeUnmount as He, nextTick as ie, toRef as Pe, openBlock as K, createBlock as le, Teleport as Ft, withDirectives as Ae, createElementBlock as _, mergeProps as ne, withKeys as Dt, withModifiers as qe, createCommentVNode as re, Transition as Xe, toHandlers as Ze, withCtx as ke, normalizeClass as Vt, normalizeStyle as Mt, vShow as Re, createVNode as Bt, renderSlot as pe, normalizeProps as ut, guardReactiveProps as Pt, createElementVNode as Z, inject as At, shallowReactive as Te, markRaw as Ce, reactive as Ke, Fragment as Rt, renderList as Je, resolveDynamicComponent as Le, createSlots as Lt, resolveComponent as xt } from "vue";
import { _ as It } from "./_plugin-vue_export-helper-hUChTQA_.js";
function st(t) {
  return Nt() ? (kt(t), !0) : !1;
}
function ct(t) {
  return typeof t == "function" ? t() : E(t);
}
const jt = typeof window < "u" && typeof document < "u";
typeof WorkerGlobalScope < "u" && globalThis instanceof WorkerGlobalScope;
const Kt = Object.prototype.toString, Gt = (t) => Kt.call(t) === "[object Object]", $t = () => {
};
function Ut(t) {
  return t || $e();
}
function Ht(t, e) {
  Ut(e) && Ct(t, e);
}
function dt(t) {
  var e;
  const n = ct(t);
  return (e = n == null ? void 0 : n.$el) != null ? e : n;
}
const zt = jt ? window : void 0;
function X(...t) {
  let e, n, o, l;
  if (typeof t[0] == "string" || Array.isArray(t[0]) ? ([n, o, l] = t, e = zt) : [e, n, o, l] = t, !e)
    return $t;
  Array.isArray(n) || (n = [n]), Array.isArray(o) || (o = [o]);
  const s = [], a = () => {
    s.forEach((b) => b()), s.length = 0;
  }, r = (b, v, g, w) => (b.addEventListener(v, g, w), () => b.removeEventListener(v, g, w)), i = H(
    () => [dt(e), ct(l)],
    ([b, v]) => {
      if (a(), !b)
        return;
      const g = Gt(v) ? { ...v } : v;
      s.push(
        ...n.flatMap((w) => o.map((D) => r(b, w, D, g)))
      );
    },
    { immediate: !0, flush: "post" }
  ), d = () => {
    i(), a();
  };
  return st(d), d;
}
/*!
* tabbable 6.2.0
* @license MIT, https://github.com/focus-trap/tabbable/blob/master/LICENSE
*/
var vt = ["input:not([inert])", "select:not([inert])", "textarea:not([inert])", "a[href]:not([inert])", "button:not([inert])", "[tabindex]:not(slot):not([inert])", "audio[controls]:not([inert])", "video[controls]:not([inert])", '[contenteditable]:not([contenteditable="false"]):not([inert])', "details>summary:first-of-type:not([inert])", "details:not([inert])"], Oe = /* @__PURE__ */ vt.join(","), ft = typeof Element > "u", oe = ft ? function() {
} : Element.prototype.matches || Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector, Fe = !ft && Element.prototype.getRootNode ? function(t) {
  var e;
  return t == null || (e = t.getRootNode) === null || e === void 0 ? void 0 : e.call(t);
} : function(t) {
  return t == null ? void 0 : t.ownerDocument;
}, De = function t(e, n) {
  var o;
  n === void 0 && (n = !0);
  var l = e == null || (o = e.getAttribute) === null || o === void 0 ? void 0 : o.call(e, "inert"), s = l === "" || l === "true", a = s || n && e && t(e.parentNode);
  return a;
}, Wt = function(e) {
  var n, o = e == null || (n = e.getAttribute) === null || n === void 0 ? void 0 : n.call(e, "contenteditable");
  return o === "" || o === "true";
}, pt = function(e, n, o) {
  if (De(e))
    return [];
  var l = Array.prototype.slice.apply(e.querySelectorAll(Oe));
  return n && oe.call(e, Oe) && l.unshift(e), l = l.filter(o), l;
}, bt = function t(e, n, o) {
  for (var l = [], s = Array.from(e); s.length; ) {
    var a = s.shift();
    if (!De(a, !1))
      if (a.tagName === "SLOT") {
        var r = a.assignedElements(), i = r.length ? r : a.children, d = t(i, !0, o);
        o.flatten ? l.push.apply(l, d) : l.push({
          scopeParent: a,
          candidates: d
        });
      } else {
        var b = oe.call(a, Oe);
        b && o.filter(a) && (n || !e.includes(a)) && l.push(a);
        var v = a.shadowRoot || // check for an undisclosed shadow
        typeof o.getShadowRoot == "function" && o.getShadowRoot(a), g = !De(v, !1) && (!o.shadowRootFilter || o.shadowRootFilter(a));
        if (v && g) {
          var w = t(v === !0 ? a.children : v.children, !0, o);
          o.flatten ? l.push.apply(l, w) : l.push({
            scopeParent: a,
            candidates: w
          });
        } else
          s.unshift.apply(s, a.children);
      }
  }
  return l;
}, mt = function(e) {
  return !isNaN(parseInt(e.getAttribute("tabindex"), 10));
}, ee = function(e) {
  if (!e)
    throw new Error("No node provided");
  return e.tabIndex < 0 && (/^(AUDIO|VIDEO|DETAILS)$/.test(e.tagName) || Wt(e)) && !mt(e) ? 0 : e.tabIndex;
}, Yt = function(e, n) {
  var o = ee(e);
  return o < 0 && n && !mt(e) ? 0 : o;
}, qt = function(e, n) {
  return e.tabIndex === n.tabIndex ? e.documentOrder - n.documentOrder : e.tabIndex - n.tabIndex;
}, ht = function(e) {
  return e.tagName === "INPUT";
}, Xt = function(e) {
  return ht(e) && e.type === "hidden";
}, Zt = function(e) {
  var n = e.tagName === "DETAILS" && Array.prototype.slice.apply(e.children).some(function(o) {
    return o.tagName === "SUMMARY";
  });
  return n;
}, Jt = function(e, n) {
  for (var o = 0; o < e.length; o++)
    if (e[o].checked && e[o].form === n)
      return e[o];
}, Qt = function(e) {
  if (!e.name)
    return !0;
  var n = e.form || Fe(e), o = function(r) {
    return n.querySelectorAll('input[type="radio"][name="' + r + '"]');
  }, l;
  if (typeof window < "u" && typeof window.CSS < "u" && typeof window.CSS.escape == "function")
    l = o(window.CSS.escape(e.name));
  else
    try {
      l = o(e.name);
    } catch (a) {
      return console.error("Looks like you have a radio button with a name attribute containing invalid CSS selector characters and need the CSS.escape polyfill: %s", a.message), !1;
    }
  var s = Jt(l, e.form);
  return !s || s === e;
}, _t = function(e) {
  return ht(e) && e.type === "radio";
}, en = function(e) {
  return _t(e) && !Qt(e);
}, tn = function(e) {
  var n, o = e && Fe(e), l = (n = o) === null || n === void 0 ? void 0 : n.host, s = !1;
  if (o && o !== e) {
    var a, r, i;
    for (s = !!((a = l) !== null && a !== void 0 && (r = a.ownerDocument) !== null && r !== void 0 && r.contains(l) || e != null && (i = e.ownerDocument) !== null && i !== void 0 && i.contains(e)); !s && l; ) {
      var d, b, v;
      o = Fe(l), l = (d = o) === null || d === void 0 ? void 0 : d.host, s = !!((b = l) !== null && b !== void 0 && (v = b.ownerDocument) !== null && v !== void 0 && v.contains(l));
    }
  }
  return s;
}, Qe = function(e) {
  var n = e.getBoundingClientRect(), o = n.width, l = n.height;
  return o === 0 && l === 0;
}, nn = function(e, n) {
  var o = n.displayCheck, l = n.getShadowRoot;
  if (getComputedStyle(e).visibility === "hidden")
    return !0;
  var s = oe.call(e, "details>summary:first-of-type"), a = s ? e.parentElement : e;
  if (oe.call(a, "details:not([open]) *"))
    return !0;
  if (!o || o === "full" || o === "legacy-full") {
    if (typeof l == "function") {
      for (var r = e; e; ) {
        var i = e.parentElement, d = Fe(e);
        if (i && !i.shadowRoot && l(i) === !0)
          return Qe(e);
        e.assignedSlot ? e = e.assignedSlot : !i && d !== e.ownerDocument ? e = d.host : e = i;
      }
      e = r;
    }
    if (tn(e))
      return !e.getClientRects().length;
    if (o !== "legacy-full")
      return !0;
  } else if (o === "non-zero-area")
    return Qe(e);
  return !1;
}, on = function(e) {
  if (/^(INPUT|BUTTON|SELECT|TEXTAREA)$/.test(e.tagName))
    for (var n = e.parentElement; n; ) {
      if (n.tagName === "FIELDSET" && n.disabled) {
        for (var o = 0; o < n.children.length; o++) {
          var l = n.children.item(o);
          if (l.tagName === "LEGEND")
            return oe.call(n, "fieldset[disabled] *") ? !0 : !l.contains(e);
        }
        return !0;
      }
      n = n.parentElement;
    }
  return !1;
}, Ve = function(e, n) {
  return !(n.disabled || // we must do an inert look up to filter out any elements inside an inert ancestor
  //  because we're limited in the type of selectors we can use in JSDom (see related
  //  note related to `candidateSelectors`)
  De(n) || Xt(n) || nn(n, e) || // For a details element with a summary, the summary element gets the focus
  Zt(n) || on(n));
}, Ge = function(e, n) {
  return !(en(n) || ee(n) < 0 || !Ve(e, n));
}, an = function(e) {
  var n = parseInt(e.getAttribute("tabindex"), 10);
  return !!(isNaN(n) || n >= 0);
}, rn = function t(e) {
  var n = [], o = [];
  return e.forEach(function(l, s) {
    var a = !!l.scopeParent, r = a ? l.scopeParent : l, i = Yt(r, a), d = a ? t(l.candidates) : r;
    i === 0 ? a ? n.push.apply(n, d) : n.push(r) : o.push({
      documentOrder: s,
      tabIndex: i,
      item: l,
      isScope: a,
      content: d
    });
  }), o.sort(qt).reduce(function(l, s) {
    return s.isScope ? l.push.apply(l, s.content) : l.push(s.content), l;
  }, []).concat(n);
}, ln = function(e, n) {
  n = n || {};
  var o;
  return n.getShadowRoot ? o = bt([e], n.includeContainer, {
    filter: Ge.bind(null, n),
    flatten: !1,
    getShadowRoot: n.getShadowRoot,
    shadowRootFilter: an
  }) : o = pt(e, n.includeContainer, Ge.bind(null, n)), rn(o);
}, un = function(e, n) {
  n = n || {};
  var o;
  return n.getShadowRoot ? o = bt([e], n.includeContainer, {
    filter: Ve.bind(null, n),
    flatten: !0,
    getShadowRoot: n.getShadowRoot
  }) : o = pt(e, n.includeContainer, Ve.bind(null, n)), o;
}, ae = function(e, n) {
  if (n = n || {}, !e)
    throw new Error("No node provided");
  return oe.call(e, Oe) === !1 ? !1 : Ge(n, e);
}, sn = /* @__PURE__ */ vt.concat("iframe").join(","), xe = function(e, n) {
  if (n = n || {}, !e)
    throw new Error("No node provided");
  return oe.call(e, sn) === !1 ? !1 : Ve(n, e);
};
/*!
* focus-trap 7.5.4
* @license MIT, https://github.com/focus-trap/focus-trap/blob/master/LICENSE
*/
function _e(t, e) {
  var n = Object.keys(t);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(t);
    e && (o = o.filter(function(l) {
      return Object.getOwnPropertyDescriptor(t, l).enumerable;
    })), n.push.apply(n, o);
  }
  return n;
}
function et(t) {
  for (var e = 1; e < arguments.length; e++) {
    var n = arguments[e] != null ? arguments[e] : {};
    e % 2 ? _e(Object(n), !0).forEach(function(o) {
      cn(t, o, n[o]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(t, Object.getOwnPropertyDescriptors(n)) : _e(Object(n)).forEach(function(o) {
      Object.defineProperty(t, o, Object.getOwnPropertyDescriptor(n, o));
    });
  }
  return t;
}
function cn(t, e, n) {
  return e = vn(e), e in t ? Object.defineProperty(t, e, {
    value: n,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : t[e] = n, t;
}
function dn(t, e) {
  if (typeof t != "object" || t === null)
    return t;
  var n = t[Symbol.toPrimitive];
  if (n !== void 0) {
    var o = n.call(t, e || "default");
    if (typeof o != "object")
      return o;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (e === "string" ? String : Number)(t);
}
function vn(t) {
  var e = dn(t, "string");
  return typeof e == "symbol" ? e : String(e);
}
var tt = {
  activateTrap: function(e, n) {
    if (e.length > 0) {
      var o = e[e.length - 1];
      o !== n && o.pause();
    }
    var l = e.indexOf(n);
    l === -1 || e.splice(l, 1), e.push(n);
  },
  deactivateTrap: function(e, n) {
    var o = e.indexOf(n);
    o !== -1 && e.splice(o, 1), e.length > 0 && e[e.length - 1].unpause();
  }
}, fn = function(e) {
  return e.tagName && e.tagName.toLowerCase() === "input" && typeof e.select == "function";
}, pn = function(e) {
  return (e == null ? void 0 : e.key) === "Escape" || (e == null ? void 0 : e.key) === "Esc" || (e == null ? void 0 : e.keyCode) === 27;
}, be = function(e) {
  return (e == null ? void 0 : e.key) === "Tab" || (e == null ? void 0 : e.keyCode) === 9;
}, bn = function(e) {
  return be(e) && !e.shiftKey;
}, mn = function(e) {
  return be(e) && e.shiftKey;
}, nt = function(e) {
  return setTimeout(e, 0);
}, ot = function(e, n) {
  var o = -1;
  return e.every(function(l, s) {
    return n(l) ? (o = s, !1) : !0;
  }), o;
}, de = function(e) {
  for (var n = arguments.length, o = new Array(n > 1 ? n - 1 : 0), l = 1; l < n; l++)
    o[l - 1] = arguments[l];
  return typeof e == "function" ? e.apply(void 0, o) : e;
}, Se = function(e) {
  return e.target.shadowRoot && typeof e.composedPath == "function" ? e.composedPath()[0] : e.target;
}, hn = [], yn = function(e, n) {
  var o = (n == null ? void 0 : n.document) || document, l = (n == null ? void 0 : n.trapStack) || hn, s = et({
    returnFocusOnDeactivate: !0,
    escapeDeactivates: !0,
    delayInitialFocus: !0,
    isKeyForward: bn,
    isKeyBackward: mn
  }, n), a = {
    // containers given to createFocusTrap()
    // @type {Array<HTMLElement>}
    containers: [],
    // list of objects identifying tabbable nodes in `containers` in the trap
    // NOTE: it's possible that a group has no tabbable nodes if nodes get removed while the trap
    //  is active, but the trap should never get to a state where there isn't at least one group
    //  with at least one tabbable node in it (that would lead to an error condition that would
    //  result in an error being thrown)
    // @type {Array<{
    //   container: HTMLElement,
    //   tabbableNodes: Array<HTMLElement>, // empty if none
    //   focusableNodes: Array<HTMLElement>, // empty if none
    //   posTabIndexesFound: boolean,
    //   firstTabbableNode: HTMLElement|undefined,
    //   lastTabbableNode: HTMLElement|undefined,
    //   firstDomTabbableNode: HTMLElement|undefined,
    //   lastDomTabbableNode: HTMLElement|undefined,
    //   nextTabbableNode: (node: HTMLElement, forward: boolean) => HTMLElement|undefined
    // }>}
    containerGroups: [],
    // same order/length as `containers` list
    // references to objects in `containerGroups`, but only those that actually have
    //  tabbable nodes in them
    // NOTE: same order as `containers` and `containerGroups`, but __not necessarily__
    //  the same length
    tabbableGroups: [],
    nodeFocusedBeforeActivation: null,
    mostRecentlyFocusedNode: null,
    active: !1,
    paused: !1,
    // timer ID for when delayInitialFocus is true and initial focus in this trap
    //  has been delayed during activation
    delayInitialFocusTimer: void 0,
    // the most recent KeyboardEvent for the configured nav key (typically [SHIFT+]TAB), if any
    recentNavEvent: void 0
  }, r, i = function(u, c, f) {
    return u && u[c] !== void 0 ? u[c] : s[f || c];
  }, d = function(u, c) {
    var f = typeof (c == null ? void 0 : c.composedPath) == "function" ? c.composedPath() : void 0;
    return a.containerGroups.findIndex(function(S) {
      var y = S.container, O = S.tabbableNodes;
      return y.contains(u) || // fall back to explicit tabbable search which will take into consideration any
      //  web components if the `tabbableOptions.getShadowRoot` option was used for
      //  the trap, enabling shadow DOM support in tabbable (`Node.contains()` doesn't
      //  look inside web components even if open)
      (f == null ? void 0 : f.includes(y)) || O.find(function(V) {
        return V === u;
      });
    });
  }, b = function(u) {
    var c = s[u];
    if (typeof c == "function") {
      for (var f = arguments.length, S = new Array(f > 1 ? f - 1 : 0), y = 1; y < f; y++)
        S[y - 1] = arguments[y];
      c = c.apply(void 0, S);
    }
    if (c === !0 && (c = void 0), !c) {
      if (c === void 0 || c === !1)
        return c;
      throw new Error("`".concat(u, "` was specified but was not a node, or did not return a node"));
    }
    var O = c;
    if (typeof c == "string" && (O = o.querySelector(c), !O))
      throw new Error("`".concat(u, "` as selector refers to no known node"));
    return O;
  }, v = function() {
    var u = b("initialFocus");
    if (u === !1)
      return !1;
    if (u === void 0 || !xe(u, s.tabbableOptions))
      if (d(o.activeElement) >= 0)
        u = o.activeElement;
      else {
        var c = a.tabbableGroups[0], f = c && c.firstTabbableNode;
        u = f || b("fallbackFocus");
      }
    if (!u)
      throw new Error("Your focus-trap needs to have at least one focusable element");
    return u;
  }, g = function() {
    if (a.containerGroups = a.containers.map(function(u) {
      var c = ln(u, s.tabbableOptions), f = un(u, s.tabbableOptions), S = c.length > 0 ? c[0] : void 0, y = c.length > 0 ? c[c.length - 1] : void 0, O = f.find(function(P) {
        return ae(P);
      }), V = f.slice().reverse().find(function(P) {
        return ae(P);
      }), A = !!c.find(function(P) {
        return ee(P) > 0;
      });
      return {
        container: u,
        tabbableNodes: c,
        focusableNodes: f,
        /** True if at least one node with positive `tabindex` was found in this container. */
        posTabIndexesFound: A,
        /** First tabbable node in container, __tabindex__ order; `undefined` if none. */
        firstTabbableNode: S,
        /** Last tabbable node in container, __tabindex__ order; `undefined` if none. */
        lastTabbableNode: y,
        // NOTE: DOM order is NOT NECESSARILY "document position" order, but figuring that out
        //  would require more than just https://developer.mozilla.org/en-US/docs/Web/API/Node/compareDocumentPosition
        //  because that API doesn't work with Shadow DOM as well as it should (@see
        //  https://github.com/whatwg/dom/issues/320) and since this first/last is only needed, so far,
        //  to address an edge case related to positive tabindex support, this seems like a much easier,
        //  "close enough most of the time" alternative for positive tabindexes which should generally
        //  be avoided anyway...
        /** First tabbable node in container, __DOM__ order; `undefined` if none. */
        firstDomTabbableNode: O,
        /** Last tabbable node in container, __DOM__ order; `undefined` if none. */
        lastDomTabbableNode: V,
        /**
         * Finds the __tabbable__ node that follows the given node in the specified direction,
         *  in this container, if any.
         * @param {HTMLElement} node
         * @param {boolean} [forward] True if going in forward tab order; false if going
         *  in reverse.
         * @returns {HTMLElement|undefined} The next tabbable node, if any.
         */
        nextTabbableNode: function(Y) {
          var q = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : !0, $ = c.indexOf(Y);
          return $ < 0 ? q ? f.slice(f.indexOf(Y) + 1).find(function(Q) {
            return ae(Q);
          }) : f.slice(0, f.indexOf(Y)).reverse().find(function(Q) {
            return ae(Q);
          }) : c[$ + (q ? 1 : -1)];
        }
      };
    }), a.tabbableGroups = a.containerGroups.filter(function(u) {
      return u.tabbableNodes.length > 0;
    }), a.tabbableGroups.length <= 0 && !b("fallbackFocus"))
      throw new Error("Your focus-trap must have at least one container with at least one tabbable node in it at all times");
    if (a.containerGroups.find(function(u) {
      return u.posTabIndexesFound;
    }) && a.containerGroups.length > 1)
      throw new Error("At least one node with a positive tabindex was found in one of your focus-trap's multiple containers. Positive tabindexes are only supported in single-container focus-traps.");
  }, w = function T(u) {
    var c = u.activeElement;
    if (c)
      return c.shadowRoot && c.shadowRoot.activeElement !== null ? T(c.shadowRoot) : c;
  }, D = function T(u) {
    if (u !== !1 && u !== w(document)) {
      if (!u || !u.focus) {
        T(v());
        return;
      }
      u.focus({
        preventScroll: !!s.preventScroll
      }), a.mostRecentlyFocusedNode = u, fn(u) && u.select();
    }
  }, I = function(u) {
    var c = b("setReturnFocus", u);
    return c || (c === !1 ? !1 : u);
  }, L = function(u) {
    var c = u.target, f = u.event, S = u.isBackward, y = S === void 0 ? !1 : S;
    c = c || Se(f), g();
    var O = null;
    if (a.tabbableGroups.length > 0) {
      var V = d(c, f), A = V >= 0 ? a.containerGroups[V] : void 0;
      if (V < 0)
        y ? O = a.tabbableGroups[a.tabbableGroups.length - 1].lastTabbableNode : O = a.tabbableGroups[0].firstTabbableNode;
      else if (y) {
        var P = ot(a.tabbableGroups, function(se) {
          var ce = se.firstTabbableNode;
          return c === ce;
        });
        if (P < 0 && (A.container === c || xe(c, s.tabbableOptions) && !ae(c, s.tabbableOptions) && !A.nextTabbableNode(c, !1)) && (P = V), P >= 0) {
          var Y = P === 0 ? a.tabbableGroups.length - 1 : P - 1, q = a.tabbableGroups[Y];
          O = ee(c) >= 0 ? q.lastTabbableNode : q.lastDomTabbableNode;
        } else
          be(f) || (O = A.nextTabbableNode(c, !1));
      } else {
        var $ = ot(a.tabbableGroups, function(se) {
          var ce = se.lastTabbableNode;
          return c === ce;
        });
        if ($ < 0 && (A.container === c || xe(c, s.tabbableOptions) && !ae(c, s.tabbableOptions) && !A.nextTabbableNode(c)) && ($ = V), $ >= 0) {
          var Q = $ === a.tabbableGroups.length - 1 ? 0 : $ + 1, ue = a.tabbableGroups[Q];
          O = ee(c) >= 0 ? ue.firstTabbableNode : ue.firstDomTabbableNode;
        } else
          be(f) || (O = A.nextTabbableNode(c));
      }
    } else
      O = b("fallbackFocus");
    return O;
  }, C = function(u) {
    var c = Se(u);
    if (!(d(c, u) >= 0)) {
      if (de(s.clickOutsideDeactivates, u)) {
        r.deactivate({
          // NOTE: by setting `returnFocus: false`, deactivate() will do nothing,
          //  which will result in the outside click setting focus to the node
          //  that was clicked (and if not focusable, to "nothing"); by setting
          //  `returnFocus: true`, we'll attempt to re-focus the node originally-focused
          //  on activation (or the configured `setReturnFocus` node), whether the
          //  outside click was on a focusable node or not
          returnFocus: s.returnFocusOnDeactivate
        });
        return;
      }
      de(s.allowOutsideClick, u) || u.preventDefault();
    }
  }, j = function(u) {
    var c = Se(u), f = d(c, u) >= 0;
    if (f || c instanceof Document)
      f && (a.mostRecentlyFocusedNode = c);
    else {
      u.stopImmediatePropagation();
      var S, y = !0;
      if (a.mostRecentlyFocusedNode)
        if (ee(a.mostRecentlyFocusedNode) > 0) {
          var O = d(a.mostRecentlyFocusedNode), V = a.containerGroups[O].tabbableNodes;
          if (V.length > 0) {
            var A = V.findIndex(function(P) {
              return P === a.mostRecentlyFocusedNode;
            });
            A >= 0 && (s.isKeyForward(a.recentNavEvent) ? A + 1 < V.length && (S = V[A + 1], y = !1) : A - 1 >= 0 && (S = V[A - 1], y = !1));
          }
        } else
          a.containerGroups.some(function(P) {
            return P.tabbableNodes.some(function(Y) {
              return ee(Y) > 0;
            });
          }) || (y = !1);
      else
        y = !1;
      y && (S = L({
        // move FROM the MRU node, not event-related node (which will be the node that is
        //  outside the trap causing the focus escape we're trying to fix)
        target: a.mostRecentlyFocusedNode,
        isBackward: s.isKeyBackward(a.recentNavEvent)
      })), D(S || a.mostRecentlyFocusedNode || v());
    }
    a.recentNavEvent = void 0;
  }, x = function(u) {
    var c = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : !1;
    a.recentNavEvent = u;
    var f = L({
      event: u,
      isBackward: c
    });
    f && (be(u) && u.preventDefault(), D(f));
  }, G = function(u) {
    if (pn(u) && de(s.escapeDeactivates, u) !== !1) {
      u.preventDefault(), r.deactivate();
      return;
    }
    (s.isKeyForward(u) || s.isKeyBackward(u)) && x(u, s.isKeyBackward(u));
  }, p = function(u) {
    var c = Se(u);
    d(c, u) >= 0 || de(s.clickOutsideDeactivates, u) || de(s.allowOutsideClick, u) || (u.preventDefault(), u.stopImmediatePropagation());
  }, N = function() {
    if (a.active)
      return tt.activateTrap(l, r), a.delayInitialFocusTimer = s.delayInitialFocus ? nt(function() {
        D(v());
      }) : D(v()), o.addEventListener("focusin", j, !0), o.addEventListener("mousedown", C, {
        capture: !0,
        passive: !1
      }), o.addEventListener("touchstart", C, {
        capture: !0,
        passive: !1
      }), o.addEventListener("click", p, {
        capture: !0,
        passive: !1
      }), o.addEventListener("keydown", G, {
        capture: !0,
        passive: !1
      }), r;
  }, h = function() {
    if (a.active)
      return o.removeEventListener("focusin", j, !0), o.removeEventListener("mousedown", C, !0), o.removeEventListener("touchstart", C, !0), o.removeEventListener("click", p, !0), o.removeEventListener("keydown", G, !0), r;
  }, B = function(u) {
    var c = u.some(function(f) {
      var S = Array.from(f.removedNodes);
      return S.some(function(y) {
        return y === a.mostRecentlyFocusedNode;
      });
    });
    c && D(v());
  }, F = typeof window < "u" && "MutationObserver" in window ? new MutationObserver(B) : void 0, W = function() {
    F && (F.disconnect(), a.active && !a.paused && a.containers.map(function(u) {
      F.observe(u, {
        subtree: !0,
        childList: !0
      });
    }));
  };
  return r = {
    get active() {
      return a.active;
    },
    get paused() {
      return a.paused;
    },
    activate: function(u) {
      if (a.active)
        return this;
      var c = i(u, "onActivate"), f = i(u, "onPostActivate"), S = i(u, "checkCanFocusTrap");
      S || g(), a.active = !0, a.paused = !1, a.nodeFocusedBeforeActivation = o.activeElement, c == null || c();
      var y = function() {
        S && g(), N(), W(), f == null || f();
      };
      return S ? (S(a.containers.concat()).then(y, y), this) : (y(), this);
    },
    deactivate: function(u) {
      if (!a.active)
        return this;
      var c = et({
        onDeactivate: s.onDeactivate,
        onPostDeactivate: s.onPostDeactivate,
        checkCanReturnFocus: s.checkCanReturnFocus
      }, u);
      clearTimeout(a.delayInitialFocusTimer), a.delayInitialFocusTimer = void 0, h(), a.active = !1, a.paused = !1, W(), tt.deactivateTrap(l, r);
      var f = i(c, "onDeactivate"), S = i(c, "onPostDeactivate"), y = i(c, "checkCanReturnFocus"), O = i(c, "returnFocus", "returnFocusOnDeactivate");
      f == null || f();
      var V = function() {
        nt(function() {
          O && D(I(a.nodeFocusedBeforeActivation)), S == null || S();
        });
      };
      return O && y ? (y(I(a.nodeFocusedBeforeActivation)).then(V, V), this) : (V(), this);
    },
    pause: function(u) {
      if (a.paused || !a.active)
        return this;
      var c = i(u, "onPause"), f = i(u, "onPostPause");
      return a.paused = !0, c == null || c(), h(), W(), f == null || f(), this;
    },
    unpause: function(u) {
      if (!a.paused || !a.active)
        return this;
      var c = i(u, "onUnpause"), f = i(u, "onPostUnpause");
      return a.paused = !1, c == null || c(), g(), N(), W(), f == null || f(), this;
    },
    updateContainerElements: function(u) {
      var c = [].concat(u).filter(Boolean);
      return a.containers = c.map(function(f) {
        return typeof f == "string" ? o.querySelector(f) : f;
      }), a.active && g(), W(), this;
    }
  }, r.updateContainerElements(e), r;
};
function gn(t, e = {}) {
  let n;
  const { immediate: o, ...l } = e, s = R(!1), a = R(!1), r = (v) => n && n.activate(v), i = (v) => n && n.deactivate(v), d = () => {
    n && (n.pause(), a.value = !0);
  }, b = () => {
    n && (n.unpause(), a.value = !1);
  };
  return H(
    () => dt(t),
    (v) => {
      v && (n = yn(v, {
        ...l,
        onActivate() {
          s.value = !0, e.onActivate && e.onActivate();
        },
        onDeactivate() {
          s.value = !1, e.onDeactivate && e.onDeactivate();
        }
      }), o && r());
    },
    { flush: "post" }
  ), st(() => i()), {
    hasFocus: s,
    isPaused: a,
    activate: r,
    deactivate: i,
    pause: d,
    unpause: b
  };
}
const wn = (t) => (...e) => {
  t && (t == null || t(...e), t = null);
}, we = () => {
};
function Ee(t, e, n) {
  return t > n ? n : t < e ? e : t;
}
const ze = (t) => typeof t == "string";
function Ie(t, e) {
  var n;
  const o = ((n = fe(t, e)) == null ? void 0 : n[0]) || e;
  t.push(o);
}
function fe(t, e) {
  const n = t.indexOf(e);
  if (n !== -1)
    return t.splice(n, 1);
}
function yt(t) {
  return Object.entries(t);
}
const Tn = {
  /**
   * @description Set `null | false` to disable teleport.
   * @default `'body'`
   * @example
   * ```js
   * teleportTo: '#modals'
   * ```
   */
  teleportTo: {
    type: [String, null, Boolean, Object],
    default: "body"
  },
  /**
   * @description An uniq name for the open/close a modal via vfm.open/vfm.close APIs.
   * @default `undefined`
   * @example Symbol: `Symbol('MyModal')`
   * @example String: `'AUniqString'`
   * @example Number: `300`
   */
  modalId: {
    type: [String, Number, Symbol],
    default: void 0
  },
  /**
   * @description Display the modal or not.
   * @default `undefined`
   * @example
   * ```js
   * const showModal = ref(false)
   * v-model="showModal"
   * ```
   */
  modelValue: {
    type: Boolean,
    default: void 0
  },
  /**
   * @description Render the modal via `if` or `show`.
   * @default `'if'`
   * @example
   * ```js
   * displayDirective: 'if'
   * ```
   * @example
   * ```js
   * displayDirective: 'show'
   * ```
   */
  displayDirective: {
    type: String,
    default: "if",
    validator: (t) => ["if", "show", "visible"].includes(t)
  },
  /**
   * @description Hide the overlay or not.
   * @default `undefined`
   * @example
   * ```js
   * hideOverlay="true"
   * ```
   */
  hideOverlay: {
    type: Boolean,
    default: void 0
  },
  /**
   * @description Customize the overlay behavior.
   */
  overlayBehavior: {
    type: String,
    default: "auto",
    validator: (t) => ["auto", "persist"].includes(t)
  },
  /**
   * @description Customize the overlay transition.
   * @default `undefined`
   */
  overlayTransition: {
    type: [String, Object],
    default: void 0
  },
  /**
   * @description Customize the content transition.
   * @default `undefined`
   */
  contentTransition: {
    type: [String, Object],
    default: void 0
  },
  /**
   * @description Bind class to vfm__overlay.
   * @default `undefined`
   */
  overlayClass: {
    type: void 0,
    default: void 0
  },
  /**
   * @description Bind class to vfm__content.
   * @default `undefined`
   */
  contentClass: {
    type: void 0,
    default: void 0
  },
  /**
   * @description Bind style to vfm__overlay.
   * @default `undefined`
   */
  overlayStyle: {
    type: [String, Object, Array],
    default: void 0
  },
  /**
   * @description Bind style to vfm__content.
   * @default `undefined`
   */
  contentStyle: {
    type: [String, Object, Array],
    default: void 0
  },
  /**
   * @description Is it allow to close the modal by clicking the overlay.
   * @default `true`
   */
  clickToClose: {
    type: Boolean,
    default: !0
  },
  /**
   * @description Is it allow to close the modal by keypress `esc`.
   * @default `true`
   */
  escToClose: {
    type: Boolean,
    default: !0
  },
  /**
   * @description Is it allow to click outside of the vfm__content when the modal is opened
   * @default `'non-interactive'`
   */
  background: {
    type: String,
    default: "non-interactive",
    validator: (t) => ["interactive", "non-interactive"].includes(t)
  },
  /**
   * @description
   * * Use `{ disabled: true }` to disable the focusTrap.
   * * Checkout the createOptions type here https://github.com/focus-trap/focus-trap for more.
   * @default `{ allowOutsideClick: true }`
   */
  focusTrap: {
    type: [Boolean, Object],
    default: () => ({
      allowOutsideClick: !0
    })
  },
  /**
   * @description Lock body scroll or not when the modal is opened.
   * @default `true`
   */
  lockScroll: {
    type: Boolean,
    default: !0
  },
  /**
   * @description Creates a padding-right when scroll is locked to prevent the page from jumping
   * @default `true`
   */
  reserveScrollBarGap: {
    type: Boolean,
    default: !0
  },
  /**
   * @description Define how to increase the zIndex when there are nested modals
   * @default `({ index }) => 1000 + 2 * index`
   */
  zIndexFn: {
    type: Function,
    default: ({ index: t }) => 1e3 + 2 * t
  },
  /**
   * @description The direction of swiping to close the modal
   * @default `none`
   * @example
   * Set swipeToClose="none" to disable swiping to close
   * ```js
   * swipeToClose="none"
   * ```
   */
  swipeToClose: {
    type: String,
    default: "none",
    validator: (t) => ["none", "up", "right", "down", "left"].includes(t)
  },
  /**
   * @description Threshold for swipe to close
   * @default `0`
   */
  threshold: {
    type: Number,
    default: 0
  },
  /**
   * @description If set `:showSwipeBanner="true"`, only allow clicking `swipe-banner` slot to swipe to close
   * @default `undefined`
   * @example
   * ```js
   * swipeToClose="right"
   * :showSwipeBanner="true"
   * ```
   * ```html
   * <VueFinalModal
   *   ...
   *   swipeToClose="right"
   *   :showSwipeBanner="true"
   * >
   *   <template #swipe-banner>
   *     <div style="position: absolute; height: 100%; top: 0; left: 0; width: 10px;" />
   *   </template>
   *   ...modal content
   * </VueFinalModal>
   * ```
   */
  showSwipeBanner: {
    type: Boolean,
    default: void 0
  },
  /**
   * @description When set `:preventNavigationGestures="true"`, there will be two invisible bars for prevent navigation gestures including swiping back/forward on mobile webkit. For example: Safari mobile.
   * @default `undefined`
   * @example
   * Set preventNavigationGestures="true" to prevent Safari navigation gestures including swiping back/forward.
   * ```js
   * :preventNavigationGestures="true"
   * ```
   */
  preventNavigationGestures: {
    type: Boolean,
    default: void 0
  }
};
function at(t = !1) {
  const e = R(t), n = R(e.value ? 0 : void 0);
  return [e, n, {
    beforeEnter() {
      n.value = 1;
    },
    afterEnter() {
      n.value = 0;
    },
    beforeLeave() {
      n.value = 3;
    },
    afterLeave() {
      n.value = 2;
    }
  }];
}
function Sn(t, e) {
  const { modelValueLocal: n, onEntering: o, onEnter: l, onLeaving: s, onLeave: a } = e, r = R(n.value), [i, d, b] = at(r.value), [v, g, w] = at(r.value), D = U(() => typeof t.contentTransition == "string" ? { name: t.contentTransition, appear: !0 } : { appear: !0, ...t.contentTransition }), I = U(() => typeof t.overlayTransition == "string" ? { name: t.overlayTransition, appear: !0 } : { appear: !0, ...t.overlayTransition }), L = U(
    () => (t.hideOverlay || g.value === 2) && d.value === 2
    /* Leave */
  );
  H(
    L,
    (x) => {
      x && (r.value = !1);
    }
  ), H(d, (x) => {
    if (x === 1) {
      if (!r.value)
        return;
      o == null || o();
    } else if (x === 0) {
      if (!r.value)
        return;
      l == null || l();
    } else
      x === 3 ? s == null || s() : x === 2 && (a == null || a());
  });
  async function C() {
    r.value = !0, await ie(), i.value = !0, v.value = !0;
  }
  function j() {
    i.value = !1, v.value = !1;
  }
  return {
    visible: r,
    contentVisible: i,
    contentListeners: b,
    contentTransition: D,
    overlayVisible: v,
    overlayListeners: w,
    overlayTransition: I,
    enterTransition: C,
    leaveTransition: j
  };
}
function En(t, e, n) {
  const { vfmRootEl: o, vfmContentEl: l, visible: s, modelValueLocal: a } = n, r = R();
  function i() {
    s.value && t.escToClose && (a.value = !1);
  }
  function d(v) {
    r.value = v == null ? void 0 : v.target;
  }
  function b() {
    var v;
    r.value === o.value && (t.clickToClose ? a.value = !1 : ((v = l.value) == null || v.focus(), e("clickOutside")));
  }
  return {
    onEsc: i,
    onMouseupRoot: b,
    onMousedown: d
  };
}
function Cn(t, e, n) {
  let o = !1;
  const { open: l, close: s } = n, a = R(!1), r = {
    get value() {
      return a.value;
    },
    set value(d) {
      i(d);
    }
  };
  function i(d) {
    (d ? l() : s()) ? (a.value = d, d !== t.modelValue && e("update:modelValue", d)) : (o = !0, e("update:modelValue", !d), ie(() => {
      o = !1;
    }));
  }
  return H(() => t.modelValue, (d) => {
    o || (r.value = !!d);
  }), {
    modelValueLocal: r
  };
}
function Nn(t, e) {
  if (t.focusTrap === !1)
    return {
      focus() {
      },
      blur() {
      }
    };
  const { focusEl: n } = e, { hasFocus: o, activate: l, deactivate: s } = gn(n, t.focusTrap);
  function a() {
    requestAnimationFrame(() => {
      l();
    });
  }
  function r() {
    o.value && s();
  }
  return { focus: a, blur: r };
}
let We = !1;
if (typeof window < "u") {
  const t = {
    get passive() {
      We = !0;
    }
  };
  window.addEventListener("testPassive", null, t), window.removeEventListener("testPassive", null, t);
}
const gt = typeof window < "u" && window.navigator && window.navigator.platform && (/iP(ad|hone|od)/.test(window.navigator.platform) || window.navigator.platform === "MacIntel" && window.navigator.maxTouchPoints > 1);
let J = [], Me = !1, Ne = 0, wt = -1, me, he;
const kn = (t) => {
  if (!t || t.nodeType !== Node.ELEMENT_NODE)
    return !1;
  const e = window.getComputedStyle(t);
  return ["auto", "scroll"].includes(e.overflowY) && t.scrollHeight > t.clientHeight;
}, On = (t, e) => !(t.scrollTop === 0 && e < 0 || t.scrollTop + t.clientHeight + e >= t.scrollHeight && e > 0), Fn = (t) => {
  const e = [];
  for (; t; ) {
    if (e.push(t), t.classList.contains("vfm"))
      return e;
    t = t.parentElement;
  }
  return e;
}, Dn = (t, e) => {
  let n = !1;
  return Fn(t).forEach((o) => {
    kn(o) && On(o, e) && (n = !0);
  }), n;
}, Tt = (t) => J.some(() => Dn(t, -Ne)), Ye = (t) => {
  const e = t || window.event;
  return Tt(e.target) || e.touches.length > 1 ? !0 : (e.preventDefault && e.preventDefault(), !1);
}, Vn = (t) => {
  if (he === void 0) {
    const e = !!t && t.reserveScrollBarGap === !0, n = window.innerWidth - document.documentElement.clientWidth;
    if (e && n > 0) {
      const o = parseInt(getComputedStyle(document.body).getPropertyValue("padding-right"), 10);
      he = document.body.style.paddingRight, document.body.style.paddingRight = `${o + n}px`;
    }
  }
  me === void 0 && (me = document.body.style.overflow, document.body.style.overflow = "hidden");
}, Mn = () => {
  he !== void 0 && (document.body.style.paddingRight = he, he = void 0), me !== void 0 && (document.body.style.overflow = me, me = void 0);
}, Bn = (t) => t ? t.scrollHeight - t.scrollTop <= t.clientHeight : !1, Pn = (t, e) => (Ne = t.targetTouches[0].clientY - wt, Tt(t.target) ? !1 : e && e.scrollTop === 0 && Ne > 0 || Bn(e) && Ne < 0 ? Ye(t) : (t.stopPropagation(), !0)), An = (t, e) => {
  if (!t) {
    console.error(
      "disableBodyScroll unsuccessful - targetElement must be provided when calling disableBodyScroll on IOS devices."
    );
    return;
  }
  if (J.some((o) => o.targetElement === t))
    return;
  const n = {
    targetElement: t,
    options: e || {}
  };
  J = [...J, n], gt ? (t.ontouchstart = (o) => {
    o.targetTouches.length === 1 && (wt = o.targetTouches[0].clientY);
  }, t.ontouchmove = (o) => {
    o.targetTouches.length === 1 && Pn(o, t);
  }, Me || (document.addEventListener("touchmove", Ye, We ? { passive: !1 } : void 0), Me = !0)) : Vn(e);
}, Rn = (t) => {
  if (!t) {
    console.error(
      "enableBodyScroll unsuccessful - targetElement must be provided when calling enableBodyScroll on IOS devices."
    );
    return;
  }
  J = J.filter((e) => e.targetElement !== t), gt ? (t.ontouchstart = null, t.ontouchmove = null, Me && J.length === 0 && (document.removeEventListener("touchmove", Ye, We ? { passive: !1 } : void 0), Me = !1)) : J.length || Mn();
};
function Ln(t, e) {
  const { lockScrollEl: n, modelValueLocal: o } = e;
  let l;
  H(n, (r) => {
    r && (l = r);
  }, { immediate: !0 }), H(() => t.lockScroll, (r) => {
    r ? a() : s();
  }), He(() => {
    s();
  });
  function s() {
    l && Rn(l);
  }
  function a() {
    o.value && t.lockScroll && l && An(l, {
      reserveScrollBarGap: t.reserveScrollBarGap,
      allowTouchMove: (r) => {
        for (; r && r !== document.body; ) {
          if (r.getAttribute("vfm-scroll-lock-ignore") !== null)
            return !0;
          r = r.parentElement;
        }
        return !1;
      }
    });
  }
  return {
    enableBodyScroll: s,
    disableBodyScroll: a
  };
}
function xn(t) {
  const e = R();
  function n(l) {
    var s;
    e.value = (s = t.zIndexFn) == null ? void 0 : s.call(t, { index: l <= -1 ? 0 : l });
  }
  function o() {
    e.value = void 0;
  }
  return {
    zIndex: e,
    refreshZIndex: n,
    resetZIndex: o
  };
}
const je = {
  beforeMount(t, { value: e }, { transition: n }) {
    t._vov = t.style.visibility === "hidden" ? "" : t.style.visibility, n && e ? n.beforeEnter(t) : ve(t, e);
  },
  mounted(t, { value: e }, { transition: n }) {
    n && e && n.enter(t);
  },
  updated(t, { value: e, oldValue: n }, { transition: o }) {
    !e != !n && (o ? e ? (o.beforeEnter(t), ve(t, !0), o.enter(t)) : o.leave(t, () => {
      ve(t, !1);
    }) : ve(t, e));
  },
  beforeUnmount(t, { value: e }) {
    ve(t, e);
  }
};
function ve(t, e) {
  t.style.visibility = e ? t._vov : "hidden";
}
const rt = (t) => {
  if (t instanceof MouseEvent) {
    const { clientX: e, clientY: n } = t;
    return { x: e, y: n };
  } else {
    const { clientX: e, clientY: n } = t.targetTouches[0];
    return { x: e, y: n };
  }
};
function In(t) {
  if (!t)
    return !1;
  let e = !1;
  const n = {
    get passive() {
      return e = !0, !1;
    }
  };
  return t.addEventListener("x", we, n), t.removeEventListener("x", we), e;
}
function jn(t, {
  threshold: e = 0,
  onSwipeStart: n,
  onSwipe: o,
  onSwipeEnd: l,
  passive: s = !0
}) {
  const a = Ke({ x: 0, y: 0 }), r = Ke({ x: 0, y: 0 }), i = U(() => a.x - r.x), d = U(() => a.y - r.y), { max: b, abs: v } = Math, g = U(
    () => b(v(i.value), v(d.value)) >= e
  ), w = R(!1), D = U(() => g.value ? v(i.value) > v(d.value) ? i.value > 0 ? "left" : "right" : d.value > 0 ? "up" : "down" : "none"), I = (h, B) => {
    a.x = h, a.y = B;
  }, L = (h, B) => {
    r.x = h, r.y = B;
  };
  let C, j;
  function x(h) {
    C.capture && !C.passive && h.preventDefault();
    const { x: B, y: F } = rt(h);
    I(B, F), L(B, F), n == null || n(h), j = [
      X(t, "mousemove", G, C),
      X(t, "touchmove", G, C),
      X(t, "mouseup", p, C),
      X(t, "touchend", p, C),
      X(t, "touchcancel", p, C)
    ];
  }
  function G(h) {
    const { x: B, y: F } = rt(h);
    L(B, F), !w.value && g.value && (w.value = !0), w.value && (o == null || o(h));
  }
  function p(h) {
    w.value && (l == null || l(h, D.value)), w.value = !1, j.forEach((B) => B());
  }
  let N = [];
  return it(() => {
    const h = In(window == null ? void 0 : window.document);
    s ? C = h ? { passive: !0 } : { capture: !1 } : C = h ? { passive: !1, capture: !0 } : { capture: !0 }, N = [
      X(t, "mousedown", x, C),
      X(t, "touchstart", x, C)
    ];
  }), {
    isSwiping: w,
    direction: D,
    coordsStart: a,
    coordsEnd: r,
    lengthX: i,
    lengthY: d,
    stop: () => {
      N.forEach((h) => h()), j.forEach((h) => h());
    }
  };
}
function Kn(t, e) {
  const { vfmContentEl: n, modelValueLocal: o } = e, l = 0.1, s = 300, a = R(), r = U(() => {
    if (!(t.swipeToClose === void 0 || t.swipeToClose === "none"))
      return t.showSwipeBanner ? a.value : n.value;
  }), i = R(0), d = R(!0);
  let b = we, v = !0, g, w = !1;
  const { lengthX: D, lengthY: I, direction: L, isSwiping: C } = jn(r, {
    threshold: t.threshold,
    onSwipeStart(p) {
      b = X(document, "selectionchange", () => {
        var N;
        d.value = (N = window.getSelection()) == null ? void 0 : N.isCollapsed;
      }), g = (/* @__PURE__ */ new Date()).getTime(), w = G(p == null ? void 0 : p.target);
    },
    onSwipe() {
      var p, N, h, B;
      if (w && d.value && L.value === t.swipeToClose) {
        if (L.value === "up") {
          const F = Ee(Math.abs(I.value || 0), 0, ((p = r.value) == null ? void 0 : p.offsetHeight) || 0) - (t.threshold || 0);
          i.value = F;
        } else if (L.value === "down") {
          const F = Ee(Math.abs(I.value || 0), 0, ((N = r.value) == null ? void 0 : N.offsetHeight) || 0) - (t.threshold || 0);
          i.value = -F;
        } else if (L.value === "right") {
          const F = Ee(Math.abs(D.value || 0), 0, ((h = r.value) == null ? void 0 : h.offsetWidth) || 0) - (t.threshold || 0);
          i.value = -F;
        } else if (L.value === "left") {
          const F = Ee(Math.abs(D.value || 0), 0, ((B = r.value) == null ? void 0 : B.offsetWidth) || 0) - (t.threshold || 0);
          i.value = F;
        }
      }
    },
    onSwipeEnd(p, N) {
      if (b(), !d.value) {
        d.value = !0;
        return;
      }
      const h = (/* @__PURE__ */ new Date()).getTime(), B = N === t.swipeToClose, F = (() => {
        var T, u;
        if (N === "up" || N === "down")
          return Math.abs((I == null ? void 0 : I.value) || 0) > l * (((T = r.value) == null ? void 0 : T.offsetHeight) || 0);
        if (N === "left" || N === "right")
          return Math.abs((D == null ? void 0 : D.value) || 0) > l * (((u = r.value) == null ? void 0 : u.offsetWidth) || 0);
      })(), W = h - g <= s;
      if (v && w && B && (F || W)) {
        o.value = !1;
        return;
      }
      i.value = 0;
    }
  }), j = U(() => {
    if (t.swipeToClose === "none")
      return;
    const p = (() => {
      switch (t.swipeToClose) {
        case "up":
        case "down":
          return "translateY";
        case "left":
        case "right":
          return "translateX";
      }
    })();
    return {
      class: { "vfm-bounce-back": !C.value },
      style: { transform: `${p}(${-i.value}px)` }
    };
  });
  H(
    () => d.value,
    (p) => {
      p || (i.value = 0);
    }
  ), H(
    () => o.value,
    (p) => {
      p && (i.value = 0);
    }
  ), H(
    () => i.value,
    (p, N) => {
      switch (t.swipeToClose) {
        case "down":
        case "right":
          v = p < N;
          break;
        case "up":
        case "left":
          v = p > N;
          break;
      }
    }
  );
  function x(p) {
    t.preventNavigationGestures && p.preventDefault();
  }
  function G(p) {
    const N = p == null ? void 0 : p.tagName;
    if (!N || ["INPUT", "TEXTAREA"].includes(N))
      return !1;
    const h = (() => {
      switch (t.swipeToClose) {
        case "up":
          return (p == null ? void 0 : p.scrollTop) + (p == null ? void 0 : p.clientHeight) === (p == null ? void 0 : p.scrollHeight);
        case "left":
          return (p == null ? void 0 : p.scrollLeft) + (p == null ? void 0 : p.clientWidth) === (p == null ? void 0 : p.scrollWidth);
        case "down":
          return (p == null ? void 0 : p.scrollTop) === 0;
        case "right":
          return (p == null ? void 0 : p.scrollLeft) === 0;
        default:
          return !1;
      }
    })();
    return p === r.value ? h : h && G(p == null ? void 0 : p.parentElement);
  }
  return {
    vfmContentEl: n,
    swipeBannerEl: a,
    bindSwipe: j,
    onTouchStartSwipeBanner: x
  };
}
const St = Symbol("vfm");
let te;
const Gn = (t) => te = t, $n = {
  install: we,
  modals: [],
  openedModals: [],
  openedModalOverlays: [],
  dynamicModals: [],
  modalsContainers: R([]),
  get: () => {
  },
  toggle: () => {
  },
  open: () => {
  },
  close: () => {
  },
  closeAll: () => Promise.allSettled([])
}, Un = () => $e() && At(St, $n) || te;
function _n() {
  const t = Te([]), e = Te([]), n = Te([]), o = Te([]), l = R([]), s = Ce({
    install(a) {
      a.provide(St, s), a.config.globalProperties.$vfm = s;
    },
    modals: t,
    openedModals: e,
    openedModalOverlays: n,
    dynamicModals: o,
    modalsContainers: l,
    get(a) {
      return t.find((r) => {
        var i, d;
        return ((d = (i = ye(r)) == null ? void 0 : i.value.modalId) == null ? void 0 : d.value) === a;
      });
    },
    toggle(a, r) {
      var i;
      const d = s.get(a);
      return (i = ye(d)) == null ? void 0 : i.value.toggle(r);
    },
    open(a) {
      return s.toggle(a, !0);
    },
    close(a) {
      return s.toggle(a, !1);
    },
    closeAll() {
      return Promise.allSettled(
        e.reduce((a, r) => {
          const i = ye(r), d = i == null ? void 0 : i.value.toggle(!1);
          return d && a.push(d), a;
        }, [])
      );
    }
  });
  return Gn(s), s;
}
function ye(t) {
  var e;
  return (e = t == null ? void 0 : t.exposed) == null ? void 0 : e.modalExposed;
}
const Hn = Ue({ inheritAttrs: !1 }), Et = /* @__PURE__ */ Ue({
  ...Hn,
  __name: "VueFinalModal",
  props: Tn,
  emits: ["update:modelValue", "beforeOpen", "opened", "beforeClose", "closed", "clickOutside"],
  setup(t, { expose: e, emit: n }) {
    const o = t, l = n, s = Ot(), a = $e(), { modals: r, openedModals: i, openedModalOverlays: d } = ge(), b = R(), v = R(), { focus: g, blur: w } = Nn(o, { focusEl: b }), { zIndex: D, refreshZIndex: I, resetZIndex: L } = xn(o), { modelValueLocal: C } = Cn(o, l, { open: Y, close: q }), { enableBodyScroll: j, disableBodyScroll: x } = Ln(o, {
      lockScrollEl: b,
      modelValueLocal: C
    });
    let G = we;
    const {
      visible: p,
      contentVisible: N,
      contentListeners: h,
      contentTransition: B,
      overlayVisible: F,
      overlayListeners: W,
      overlayTransition: T,
      enterTransition: u,
      leaveTransition: c
    } = Sn(o, {
      modelValueLocal: C,
      onEntering() {
        ie(() => {
          x(), g();
        });
      },
      onEnter() {
        l("opened"), G("opened");
      },
      onLeave() {
        fe(i, a), L(), j(), l("closed"), G("closed");
      }
    }), { onEsc: f, onMouseupRoot: S, onMousedown: y } = En(o, l, { vfmRootEl: b, vfmContentEl: v, visible: p, modelValueLocal: C }), {
      swipeBannerEl: O,
      bindSwipe: V,
      onTouchStartSwipeBanner: A
    } = Kn(o, { vfmContentEl: v, modelValueLocal: C }), P = U(() => a ? i.indexOf(a) : -1);
    H([() => o.zIndexFn, P], () => {
      p.value && I(P.value);
    }), it(() => {
      Ie(r, a);
    }), o.modelValue && (C.value = !0);
    function Y() {
      let m = !1;
      return l("beforeOpen", { stop: () => m = !0 }), m ? !1 : (Ie(i, a), Ie(d, a), $(), u(), !0);
    }
    function q() {
      let m = !1;
      return l("beforeClose", { stop: () => m = !0 }), m ? !1 : (fe(d, a), $(), w(), c(), !0);
    }
    He(() => {
      j(), fe(r, a), fe(i, a), w(), $();
    });
    async function $() {
      await ie();
      const m = d.filter((k) => {
        var M;
        const z = ye(k);
        return (z == null ? void 0 : z.value.overlayBehavior.value) === "auto" && !((M = z == null ? void 0 : z.value.hideOverlay) != null && M.value);
      });
      m.forEach((k, M) => {
        const z = ye(k);
        z != null && z.value && (z.value.overlayVisible.value = M === m.length - 1);
      });
    }
    const Q = Pe(() => o.modalId), ue = Pe(() => o.hideOverlay), se = Pe(() => o.overlayBehavior), ce = U(() => ({
      modalId: Q,
      hideOverlay: ue,
      overlayBehavior: se,
      overlayVisible: F,
      toggle(m) {
        return new Promise((k) => {
          G = wn((z) => k(z));
          const M = typeof m == "boolean" ? m : !C.value;
          C.value = M;
        });
      }
    }));
    return e({
      modalExposed: ce
    }), (m, k) => (K(), le(Ft, {
      to: m.teleportTo ? m.teleportTo : void 0,
      disabled: !m.teleportTo
    }, [
      m.displayDirective !== "if" || E(p) ? Ae((K(), _("div", ne({ key: 0 }, E(s), {
        ref_key: "vfmRootEl",
        ref: b,
        class: ["vfm vfm--fixed vfm--inset", { "vfm--prevent-none": m.background === "interactive" }],
        style: { zIndex: E(D) },
        role: "dialog",
        "aria-modal": "true",
        onKeydown: k[7] || (k[7] = Dt(() => E(f)(), ["esc"])),
        onMouseup: k[8] || (k[8] = qe(() => E(S)(), ["self"])),
        onMousedown: k[9] || (k[9] = qe((M) => E(y)(M), ["self"]))
      }), [
        ue.value ? re("", !0) : (K(), le(Xe, ne({ key: 0 }, E(T), Ze(E(W))), {
          default: ke(() => [
            m.displayDirective !== "if" || E(F) ? Ae((K(), _("div", {
              key: 0,
              class: Vt(["vfm__overlay vfm--overlay vfm--absolute vfm--inset vfm--prevent-none", m.overlayClass]),
              style: Mt(m.overlayStyle),
              "aria-hidden": "true"
            }, null, 6)), [
              [Re, m.displayDirective !== "show" || E(F)],
              [E(je), m.displayDirective !== "visible" || E(F)]
            ]) : re("", !0)
          ]),
          _: 1
        }, 16)),
        Bt(Xe, ne(E(B), Ze(E(h))), {
          default: ke(() => [
            m.displayDirective !== "if" || E(N) ? Ae((K(), _("div", ne({
              key: 0,
              ref_key: "vfmContentEl",
              ref: v,
              class: ["vfm__content vfm--outline-none", [m.contentClass, { "vfm--prevent-auto": m.background === "interactive" }]],
              style: m.contentStyle,
              tabindex: "0"
            }, E(V), {
              onMousedown: k[6] || (k[6] = () => E(y)())
            }), [
              pe(m.$slots, "default", ut(Pt({ close: q }))),
              m.showSwipeBanner ? (K(), _("div", {
                key: 0,
                ref_key: "swipeBannerEl",
                ref: O,
                class: "vfm-swipe-banner-container",
                onTouchstart: k[2] || (k[2] = (M) => E(A)(M))
              }, [
                pe(m.$slots, "swipe-banner", {}, () => [
                  Z("div", {
                    class: "vfm-swipe-banner-back",
                    onTouchstart: k[0] || (k[0] = (M) => m.swipeToClose === "left" && M.preventDefault())
                  }, null, 32),
                  Z("div", {
                    class: "vfm-swipe-banner-forward",
                    onTouchstart: k[1] || (k[1] = (M) => m.swipeToClose === "right" && M.preventDefault())
                  }, null, 32)
                ])
              ], 544)) : !m.showSwipeBanner && m.preventNavigationGestures ? (K(), _("div", {
                key: 1,
                class: "vfm-swipe-banner-container",
                onTouchstart: k[5] || (k[5] = (M) => E(A)(M))
              }, [
                Z("div", {
                  class: "vfm-swipe-banner-back",
                  onTouchstart: k[3] || (k[3] = (M) => m.swipeToClose === "left" && M.preventDefault())
                }, null, 32),
                Z("div", {
                  class: "vfm-swipe-banner-forward",
                  onTouchstart: k[4] || (k[4] = (M) => m.swipeToClose === "right" && M.preventDefault())
                }, null, 32)
              ], 32)) : re("", !0)
            ], 16)), [
              [Re, m.displayDirective !== "show" || E(N)],
              [E(je), m.displayDirective !== "visible" || E(N)]
            ]) : re("", !0)
          ]),
          _: 3
        }, 16)
      ], 16)), [
        [Re, m.displayDirective !== "show" || E(p)],
        [E(je), m.displayDirective !== "visible" || E(p)]
      ]) : re("", !0)
    ], 8, ["to", "disabled"]));
  }
});
function ge() {
  const t = Un();
  if (!t)
    throw new Error(
      `[Vue Final Modal]: getActiveVfm was called with no active Vfm. Did you forget to install vfm?
	const vfm = createVfm()
	app.use(vfm)
This will fail in production.`
    );
  return t;
}
function lt(t, e = Et) {
  const { component: n, slots: o, ...l } = t, s = typeof o > "u" ? {} : Object.fromEntries(yt(o).map(([a, r]) => ze(r) ? [a, r] : Be(r) ? [a, {
    ...r,
    component: Ce(r.component)
  }] : [a, Ce(r)]));
  return {
    ...l,
    component: Ce(n || e),
    slots: s
  };
}
function eo(t) {
  const e = Ke({
    id: Symbol("useModal"),
    modelValue: !!(t != null && t.defaultModelValue),
    resolveOpened: () => {
    },
    resolveClosed: () => {
    },
    attrs: {},
    ...lt(t)
  });
  Ht(() => {
    e != null && e.keepAlive || r();
  }), e.modelValue === !0 && (te ? te == null || te.dynamicModals.push(e) : ie(() => {
    const i = ge();
    i == null || i.dynamicModals.push(e);
  }));
  async function n() {
    let i;
    return te ? i = te : (await ie(), i = ge()), e.modelValue ? Promise.resolve("[Vue Final Modal] modal is already opened.") : (r(), e.modelValue = !0, i.dynamicModals.push(e), new Promise((d) => {
      e.resolveOpened = () => d("opened");
    }));
  }
  function o() {
    return e.modelValue ? (e.modelValue = !1, new Promise((i) => {
      e.resolveClosed = () => i("closed");
    })) : Promise.resolve("[Vue Final Modal] modal is already closed.");
  }
  function l(i) {
    const { slots: d, ...b } = lt(i, e.component);
    i.defaultModelValue !== void 0 && (e.defaultModelValue = i.defaultModelValue), (i == null ? void 0 : i.keepAlive) !== void 0 && (e.keepAlive = i == null ? void 0 : i.keepAlive), s(e, b), d && yt(d).forEach(([v, g]) => {
      const w = e.slots[v];
      ze(w) ? e.slots[v] = g : Be(w) && Be(g) ? s(w, g) : e.slots[v] = g;
    });
  }
  function s(i, d) {
    d.component && (i.component = d.component), d.attrs && a(i.attrs, d.attrs);
  }
  function a(i, d) {
    return Object.entries(d).forEach(([b, v]) => {
      i[b] = v;
    }), i;
  }
  function r() {
    const i = ge(), d = i.dynamicModals.indexOf(e);
    d !== -1 && i.dynamicModals.splice(d, 1);
  }
  return {
    options: e,
    open: n,
    close: o,
    patchOptions: l,
    destroy: r
  };
}
function to(t) {
  return t;
}
function Be(t) {
  return typeof t == "object" && t !== null ? "component" in t : !1;
}
const zn = ["innerHTML"], no = /* @__PURE__ */ Ue({
  __name: "ModalsContainer",
  setup(t) {
    const { modalsContainers: e, dynamicModals: n } = ge(), o = Symbol("ModalsContainer"), l = U(() => {
      var r;
      return o === ((r = e.value) == null ? void 0 : r[0]);
    });
    e.value.push(o), He(() => {
      e.value = e.value.filter((r) => r !== o);
    });
    function s(r) {
      var i, d, b;
      (d = (i = n[r]) == null ? void 0 : i.resolveClosed) == null || d.call(i), (b = n[r]) != null && b.keepAlive || n.splice(r, 1);
    }
    function a(r) {
      var i, d;
      (d = (i = n[r]) == null ? void 0 : i.resolveOpened) == null || d.call(i);
    }
    return (r, i) => l.value ? (K(!0), _(Rt, { key: 0 }, Je(E(n), (d, b) => (K(), le(Le(d.component), ne({
      key: d.id
    }, {
      displayDirective: d != null && d.keepAlive ? "show" : void 0,
      ...typeof d.attrs == "object" ? d.attrs : {}
    }, {
      modelValue: d.modelValue,
      "onUpdate:modelValue": (v) => d.modelValue = v,
      onClosed: () => s(b),
      onOpened: () => a(b)
    }), Lt({ _: 2 }, [
      Je(d.slots, (v, g) => ({
        name: g,
        fn: ke(() => [
          E(ze)(v) ? (K(), _("div", {
            key: 0,
            innerHTML: v
          }, null, 8, zn)) : E(Be)(v) ? (K(), le(Le(v.component), ut(ne({ key: 1 }, v.attrs)), null, 16)) : (K(), le(Le(v), { key: 2 }))
        ])
      }))
    ]), 1040, ["modelValue", "onUpdate:modelValue", "onClosed", "onOpened"]))), 128)) : re("", !0);
  }
}), Wn = {
  components: {
    VueFinalModal: Et
  },
  inheritAttrs: !1,
  props: {
    size: {
      type: String,
      default: ""
    },
    isVerticallyCentered: {
      type: Boolean,
      default: !1
    },
    isScrollableBody: {
      type: Boolean,
      default: !1
    }
  },
  emits: [
    "beforeOpen",
    "opened",
    "beforeClose",
    "closed",
    "update:modelValue"
  ],
  setup(t, { emit: e }) {
    return {
      close: () => e("update:modelValue", !1)
    };
  }
}, Yn = { class: "modal-content" }, qn = { class: "modal-header" }, Xn = { class: "modal-body" };
function Zn(t, e, n, o, l, s) {
  const a = xt("VueFinalModal");
  return K(), le(a, ne(t.$attrs, {
    class: "modal d-block",
    "content-class": {
      "modal-dialog": !0,
      "modal-dialog-centered": n.isVerticallyCentered,
      "modal-dialog-scrollable": n.isScrollableBody,
      [`modal-${n.size}`]: n.size
    },
    "content-transition": "vfm-fade",
    "overlay-transition": "vfm-fade",
    onBeforeOpen: e[1] || (e[1] = (r) => t.$emit("beforeOpen")),
    onOpened: e[2] || (e[2] = (r) => t.$emit("opened")),
    onBeforeClose: e[3] || (e[3] = (r) => t.$emit("beforeClose")),
    onClosed: e[4] || (e[4] = (r) => t.$emit("closed")),
    "onUpdate:modelValue": e[5] || (e[5] = (r) => t.$emit("update:modelValue", r))
  }), {
    default: ke(() => [
      Z("div", Yn, [
        pe(t.$slots, "default", { close: o.close }, () => [
          Z("div", qn, [
            pe(t.$slots, "title"),
            Z("button", {
              type: "button",
              class: "btn-close",
              "aria-label": "Close",
              onClick: e[0] || (e[0] = (...r) => o.close && o.close(...r))
            })
          ]),
          Z("div", Xn, [
            pe(t.$slots, "body", { close: o.close })
          ])
        ])
      ])
    ]),
    _: 3
  }, 16, ["content-class"]);
}
const oo = /* @__PURE__ */ It(Wn, [["render", Zn]]);
export {
  no as ModalsContainer,
  oo as VModal,
  _n as createModal,
  eo as useModal,
  to as useModalSlot,
  ge as useVfm
};
